import React from "react"
import Layout from "components/layout"
import usePageData from 'hooks/querySustainability.vi';
import ViewSustainability from 'components/ViewSustainability';

const SustainabilityPage = props => {
  const { pageContext } = props;
  const pageData = usePageData();

  return (
    <Layout pageContext={pageContext}>
      <ViewSustainability pageData={pageData} />
    </Layout>
  )
}

export default SustainabilityPage
